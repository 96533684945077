<template>
	<div class="col-12">
		<div class="row">
			<div class="col-12 col-md-5 col-lg-5 profile-container" >

			</div>
			<div class="col-10 col-md-6 col-lg-6 card-body bio-panel">
				<div class="intro-block">
					<p class="text-primary">{{typing}} |</p>
					<h4 style="line-height:1.5em;">
						Hello, I’m <span class="text-primary">Roger Kirton</span>, a Full-stack Web App Developer, Mobile App Developer, Rotarian and Manchester United fan based in the Caribbean - Trinidad and Tobago to be exact.
					</h4>
					<!-- <h4 style="line-height:1.5em" class="text-muted my-2">
						I also have experience with Wordpress, building and deploying apps using a LAMP Stack and Blockchain. I would love to talk with you about deploying your next project.
					</h4> -->
					<div class="contact-block my-4 ml-3">
						<p><a href="mailto:rjkirton@kirtontt.com"><i class="fas fa-envelope ml-2 mr-2"></i>rjkirton@kirtontt.com</a></p>
						<p><a href="tel:+18687794524"><i class="fas fa-phone ml-2 mr-2"></i>+1 868 779 4524</a></p>
						<p><a href="https://wa.me/18687794524"><i class="fab fa-whatsapp ml-2 mr-2"></i>+1 868 779 4524</a></p>
					</div>
					<div class="skill-block my-5">
						<h4>Coding and Design Skills</h4>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Vue.js</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Firebase</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">React</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Node.js</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Javacscript</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">HTML</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">CSS</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">php + mySQL</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Blockchain</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">GraphQL</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Nativescript</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Illustrator</button>
						<button class="btn btn-sm btn-outline-dark mr-2 my-2">Photoshop</button>
					</div>

				</div>
			</div>
			<div class="col-2 col-md-1">
				<div class="social-container" align="center">
					<div class="social-block h3 text-primary">
						<div class="my-3">
							<a href="https://www.linkedin.com/in/rogerkirton/" target="_socialmedia">
								<i class="fab fa-linkedin mr-2"></i>
							</a>
						</div>
						<div class="my-3">
							<a href="https://instagram.com/rogerkirton" target="_socialmedia">
								<i class="fab fa-instagram mr-2"></i>
							</a>
						</div>
						<div class="my-3">
							<a href="https://www.facebook.com/roger.kirton" target="_socialmedia">
								<i class="fab fa-facebook mr-2"></i>
							</a>
						</div>
						<div class="my-3">
							<a href="https://twitter.com/kirtontt" target="_socialmedia">
								<i class="fab fa-twitter mr-2"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'Home',
    components: {
      //HelloWorld
    },
	computed:{

	},
	data(){
		return{
			subtitles:["Web App Developer", "Mobile App Developer", "Consultant"],
			typing: '',
			count: 0,
			speed:50,
			subtitlePos:0,

		}

	},
	methods:{
		typeWriter(){
			if (this.count < this.subtitles[this.subtitlePos].length) {
				this.typing += `${this.subtitles[this.subtitlePos].charAt(this.count)}`
				this.count++
				setTimeout(this.typeWriter, this.speed)
			}else{
				setTimeout(this.reverseTypeWriter, 800)
			}
		},
		reverseTypeWriter(){
			if (this.count > -1) {
				this.typing = `${this.subtitles[this.subtitlePos].slice(0, this.count)}`
				this.count--
				setTimeout(this.reverseTypeWriter, this.speed)
			}else{
				this.subtitlePos ++
				if(this.subtitlePos == this.subtitles.length) this.subtitlePos = 0
				setTimeout(this.typeWriter, this.speed)
			}
		}
	},
	mounted(){
		this.typeWriter()
	}
}
</script>
